.form{
    /* border:1px solid red; */
}
.forms{
    padding-left: 10px;
 text-align: center;
 width: fit-content !important;
 margin: auto;
    /* border:1px solid green; */
}
.partner_card-height{
    margin-top: 50px;
    box-sizing: border-box;
    max-width: 100%;
    height: 490px;
    align-items: flex-start;
    font-size: 16px;
    font-family: "Lato-Regular";
    /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); */
    box-shadow: 0 0 50px #ecea691f;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    transition: color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}
.programm__title-overview{
    text-align: center;
    margin: auto;
    margin-top: 50px;
    width: 50%;
    background: #fff;
    box-shadow: 0 0 50px rgba(13,19,80,0.05);
    border-radius: 10px;
}
.custom-form_no-border-overview {
    box-shadow: none !important;
    width: 60%;
margin: auto;
}
.title_align-center{
    text-align: center;
padding-top: 20px !important;
padding-bottom: 20px  !important;
}
.open-live-overview{
    text-align: -webkit-center;
    padding-bottom: 20px  !important;

}
a{
    text-decoration: none !important;
    /* color:red; */
}
.new-check-box{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* border:1px solid red; */
}
.New-check{
    /* border:1px solid blue; */

}
.check-box-paragraph{
    /* border:1px solid green; */

}
.our-blogs{
    width:100%;
    height: 60%;
    /* background-color: #fff; */
    padding-top: 50px;
}
.About-top{
    padding-top: 50px;
    text-align: center;
}
.About-Header-top{
    color: var(--nav-link-color);
    font-size: 40px;
}
.About-Header{
   
    color: var(--nav-link-color);
}
.flex-columns {
    column-gap: 20px !important;
    /* row-gap: 0px; */
    display: flex;
    margin-top: 60px;
   
    /* flex-wrap: wrap; */
    margin-bottom: 120px;
    justify-content: space-between !important;

}
.card, .card_inline {
    align-items: center;
}
.card_colored {
    padding: 0;
    overflow: hidden;
}
.card {
    background: #fff;
    box-shadow: 0 0 50px rgba(13,19,80,0.05);
    border-radius: 10px;
    /* padding: 32px; */
    max-width: 320px;
    display: flex;
    flex-direction: column;
    justify-self: center;
    width: 100%;
    position: relative;
    box-sizing: border-box;
}
.card__title_colored {
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #fff;
    background: var(--account);
    margin: 0;
    padding: 24px 16px;
    text-align: center;
    width: 100%;
}
.card__description_padding_24 {
    /* padding: 24px; */
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}
.card__description {
    padding: 20px;
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--card-details);
    margin: 24px 0 0;
    text-align: left;
}
.check-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.check-list__item {
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7d7987;
    display: grid;
    /* gap: 16px; */
    grid-template-columns: 18px 1fr;
}
.check-list__item:before {
    content: "";
    /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5 1a4 4 0 0 0-4 4v14a4 4 0 0 0 4 4h14a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5zm14.707 6.707a1 1 0 0 0-1.414-1.414L9 15.586l-3.293-3.293a1 1 0 0 0-1.414 1.414l4 4a1 1 0 0 0 1.414 0l10-10z' fill='%23985EFF'/%3E%3C/svg%3E"); */
    width: 18px;
    height: 18px;
    background-size: cover;
    display: block;
    margin-top: 5px;
}
.two-buttons_space-between {
    align-self: flex-end;
    justify-content: space-between;
    display: flex;
    width: 100%;
}
.two-buttons {
    display: flex;
    justify-content: space-between;
    max-width: 600px;
    font-family: "Lato-Regular";
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #7d7987;
    align-items: center;
    margin: 0 auto;
    padding: 0 16px;
    margin-top: 64px;
}
.button_no-border {
    background: #fff;
    color: #742cce;
    padding-left: 0;
    padding-right: 0;
}
.button_small {
    font-size: 16px !important;
    /* padding: 12px 24px !important; */
font-family: "Lato-Regular";
}
.button {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    /* padding: 16px 32px; */
    /* background: linear-gradient(90deg, #742cce, #1811a2); */
    backdrop-filter: blur(80px);
    border-radius: 10px;
    font-family: "Lato-Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    width: fit-content;
}
.button_primary {
    background: linear-gradient(90deg, #742cce, #1811a2);
    text-transform: uppercase;
    color: #fff !important;
}
.how-it-works_max-920 {
    margin-top: 90px !important;
    max-width: 1000px;
    margin: 0 auto;
}
    .how-it-works__title {
        margin-top: 120px;
        font-family: "Lato-Regular";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        text-align: center;
        color: #0d1350;
    }
    .how-it-works__table {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-content: center;
        max-width: 1200px;
        margin: 0 auto 96px;
        padding: 0 16px;
        gap: 20px;
    }
    .how-it-works__table-item_no-padding {
        padding-top: 0;
    }
    .how-it-works__table-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        max-width: 320px;
        position: relative;
    }
    /* .how-it-works__table-item_step-2 {
        padding-top: 75px;
        position: relative;
    }
    .how-it-works__table-item_step-3 {
        padding-top: 150px;
    } */


    .custom-forms {
        margin-top: 70px !important;
        padding-bottom: 50px;
        max-width: 920px;
        margin: 0 auto;
    }
    .custom-forms, .programm__title {
        background: #fff;
        box-shadow: 0 0 50px rgba(13,19,80,0.05);
        border-radius: 10px;
    }
    .custom-form__title {
        padding: 48px 16px;
        background: var( --button-bg-light);
        border-radius: 10px 10px 0 0;
        margin-bottom: 80px;
    }
    .title_color_white {
        color: #fff;
    }
    .subtitles {
        font-family: "Lato-Regular";
        font-size: 20px;
        line-height: 32px;
        padding: 0;
        color: var(--nav-link-color);
    }
    .subtitle_align-centers {
        text-align: center !important;
    }
    .custom-form__body {
                border: 0px solid #dadfec !important; 
        padding: 0 80px;
        margin-bottom: 48px;
    }
    .custom-form__section-title {
        font-family: "Lato-Regular";
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: var(--nav-link-color);
        margin-bottom: 30px;
    }
    .field_checkbox {
        display: flex;
        position: relative;
        padding-left: 36px;
        user-select: none;
        cursor: pointer;
        border: none;
        font-family: "Lato-Regular";
        font-size: 20px;
        line-height: 32px;
        color: var(--nav-link-color);
        align-items: center;
        height: 24px;
        margin: 16px 16px 16px 0;
    }
    .field__input_checkboxs {
        /* box-sizing: border-box;
        padding: 0; */
        position: absolute;
        /* opacity: 0; */
        cursor: pointer;
        /* height: 0;
        width: 0; */
    }
    .field__inputs {
        margin: 0;
        overflow: visible;
        font-family: "Lato-Regular";
        font-size: 16px;
        line-height: 24px;
        /* width: 100%; */
        height: 50px;
        outline: none;
        padding: 10px 15px;
        background-color: transparent;
        border: transparent;
        box-sizing: border-box;
    }
    .fields__input {
        margin: 0;
        margin-right: 10px;
        overflow: visible;
        font-family: "Lato-Regular";
        font-size: 20px;
        line-height: 24px;
        /* width: 100%; */
        height: 50px;
        outline: none;
        padding: 10px 15px;
        background-color: transparent;
        border: transparent;
        box-sizing: border-box;
    }
    .field__input_checkbox:checked ~ .field__checkmark_radio {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='11.5' stroke='%230D1350'/%3E%3Ccircle cx='12' cy='12' r='8' fill='%230D1350'/%3E%3C/svg%3E");
    }
    .custom-form__body {
        padding: 0 80px;
        margin-bottom: 48px;
    }
    .columns_gap_40 {
        gap: 40px;
    }
    .columns_no-margin-bottom {
        margin-bottom: 0;
    }
    .columns_two {
        grid-template-columns: 1fr 1fr;
        /* gap: 120px 50px; */
        align-items: center;
    }
    .columns {
        display: grid;
        margin-bottom: 120px;
    }
    .field-c {
        min-width: 0;
        padding: 0;
        margin: 15px 0;
        position: relative;
        /* border: 1px solid #dadfec; */
        border-radius: 3px;
        width: 100%;
    }
    .field__legend {
        display: block;
        max-width: 100%;
        line-height: inherit;
        white-space: normal;
        width: auto;
        padding: 2px 6px;
        margin-left: 10px;
        font-family: "Lato-Regular";
        font-size: 12px;
        line-height: 160%;
        color: #0d1350;
        font-weight: 400;
        position: absolute;
        top: -14px;
        background: #fff;
    }
    .form-block{
        padding-left:100px;
    }
    .text-field-o{
        padding-left:20px;

    }
    .icon-text{
        margin-left: -30px;
        display: flex;
        justify-content: flex-start;
        align-items: start;
    }
    .icon-text>p{
        padding-top: 35px;
        padding-left: 10px;
    }
    .icon-text>span{
        padding-top: 35px;
        padding-left: 5px;
    }
    .icon-texts{
        display: flex;
        justify-content: flex-start;
        align-items: start;
    }
    .custom-form__section-title{
        padding-top: 35px;
padding-left: 10px;
    }
    .radio-buttons{
        padding-left: 25px;
  
    }
    .custom-form__section-titles{
        text-align: start;
        font-size: 20px;
        font-family: "Lato-Regular";
        font-weight: bold;
    }

    @media (min-width:320px) and (max-width:765px){
        .custom-form_no-border-overview{
            width:100%;
        }
        .programm__title-overview{
width: 100%;
margin: auto;
        }
        .platform__contact-us-input {
            margin-bottom: 20px !important;
    
        }
        .how-it-works__table{
            width: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
        }
        .how-it-works__table-item{
            width: 100%;
            box-shadow: 0 0 20px rgba(13,19,80,0.05);
            padding-left:10px !important;
            padding-right:10px !important;
        }
        .ico{
            margin-left: 0px !important ;
        }
        .subtitle{
            text-align: center !important;
            font-size: 17px ;
        }
        .flex-columns{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
        }
        .card{
            margin: 20px 0px;
        }
        .button-class{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: 20vh;
        }
        .main__why-we-are-different-card{
            height: fit-content !important;
        }
        .custom-form__body {
            padding: 10px !important;
        }
        .custom-form__body p{
            width: 100%;
        }
        .radio-buttons{
            padding-left: 0px;
        }
        .form-block{
            padding-left: 0px !important;
        }
        .icon-text, .icon-texts{
            margin-left: 0px !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
        }
        .icon-text>p , .custom-form__section-title{
            padding-top: 0px !important;
            font-size: 17px ;
            margin-top: 30px;
        }
        .columns{
            margin-bottom: 0px !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
        }
        .custom-form__section-title{
            width: 100% !important;
        }
        .mob-tick{
            padding-top: 0px !important;
            font-size: 16px !important;
        }
        .block-form-body{
            width: 95%;
            margin: auto;
        }
        .field_checkbox {

            font-size: 15px;
        }
        .field{
            border: 1px solid blue;
            width: 95% !important;
            margin: auto !important;
        }
        .button-class {
        width: 95%;
        margin: auto;
        }
        #get-a-live-account{
            min-height: 35px !important;
        }

    }