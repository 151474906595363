.header-login{
  background-color: transparent;
  width:70px;
  height:40px;
  margin-right: 20px;
  border-radius: 10px;
 font-family: "Lato-Regular";
 backdrop-filter: blur(80px);
 border: none;
 font-size: 15px;
}
.hell{
  position: sticky;
  top: 0;
  z-index: 99999999;
}
.header-login a{
  color: var(--nav-link-color);
}
.button_wrapper a{
  text-decoration:  none;

}

.header-open-account{
  border:none;
  width:70%;
  height:50px;
  border-radius: 10px;
  
  background: var(--button-bg);
  color: #fff;
  font-size: 15px;
}
.header-open-account:hover{
  opacity: .7;
  text-decoration: none;
}
.create-link{
  color:#ffffff !important;
}

.header-button{
  width:30%;
  display:contents;
  justify-content: space-between;
}
.button_wrapper{
  height:inherit;
   display:flex;
   width:30%;
   align-items: center;
   font-size: 15px;
   justify-content: space-between;
}
.nav-wrapper{
  width: 65%;
  font-size: 10%;
  height: 100%;
}
nav .wapper{
  max-width: 1440px;
  margin: auto;
  display: flex;
  align-items: center;
  
  justify-content: space-between;
  height: 100%;
  background-color: #ffff;
  position: sticky;
  top: 0;
}
.spacing-div{
    width: 85%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
   .nav-links{
    width: 100%;
    height: 100%;
  }
ul .nav-links a{
    justify-content: space-between;
}
.nav-links li a{
     text-decoration: none;
     font-size: 16px;   
     font-weight: 500;
    transition: all 0.3s ease;
     border-radius:5px ;
     font-family: "Lato-Regular";
     color: var(--nav-link-color);
}
.wapper .nav-links{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}
.nav-links li{
    list-style: none;
}
.logo{
  height: 90%;
  width: 100px;
}
.logo img{
    width: 100%;
    height: 100%;
    cursor: pointer;
    /* display: none; */
}
nav{
    background-color: white;
    position: sticky;
    z-index: 999;
    height: 80px;
    top: 0;
    width: 100%;
    margin: 0 auto;
    box-shadow: 0 2px 4px rgba(13,19,80,0.06);;
}
.nav-links .drop-down{
    width: 190px;
    top: 68px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
}
.nav-links li:hover  .drop-down,
.nav-links li:hover .mega-box{
    opacity: 1;
    top: 80px;
    transition: all 0.3s ease;
    visibility: visible;
    /* background-color: greenyellow; */
}

.nav-links {
  /* border: 1px solid yellow; */
  /* padding-bottom: 10px; */
}
.nav-links li{
  border: 3px solid transparent;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: baseline;

}
.nav-links li:hover{
  border-bottom: 3px solid var(--button-bg);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: baseline;
}
.drop-down li a{
    width: 100%;
    display: block;
    padding: 0 0 0  15px;
    font-weight: 400;
}
.mega-box{
    position: absolute;
    top: 0px;
  width: 111%;
    left: -5.5%;
    top: 90px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(13,19,80,0.06) !important;
}
.content .row:nth-child(1),
.content .row:nth-child(2){
    border-left: 0px;
}


.mega-box .content{
  /* border:1px solid red; */
    background: rgb(255, 255, 255);
    padding: 20px 5%;
    width: 102%;
    margin-left: -10px;
   display: flex;
   align-items: center;
   justify-content: start;
   background-color: #f9f9fe;
}
.mega-links li a{
    /* padding-top: 15px; */
}
.button{
    width: 38%;
    display: flex;
}
.button .p1{
    padding-left: 30px;
}
.box-1{
     text-align: center;
     width: 300px ;
     height: fit-content;
}
.vision{
  font-size: 14px;
  font-family: 'Lato-Regular';
  color: var(--card-details);
}
.vision-hddr{
  font-family: 'Lato-Regular';
  font-size: 15px;
  margin-top: 6%;
  color: var(--nav-link-color);
}
.vision-img{
  height: 32px;
  width: 32px;
}
.box-2{
  /* border:1px solid green; */
  width: 118%;
}
.box-3{
    width:320px;
    text-align: center;
}
.content .row{
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
}
.link-info{
  margin-left: 110px !important;
}
.fx-market{
  margin-left: 4%;
}

.fx-market .box-2{
  height: 100%;
  padding-left: 40px;
}
.box-2 .fusion-header{
  font-size: 18px;
  font-family: 'Lato-Bold';
  color: var(--nav-link-color);
}
.fx-market .mega-links{
  padding-left: 0px !important;
  font-family: 'Lato-Regular';
  font-size: 14px;
  /* line-height: 24px; */
  color: var(--nav-link-color);
}
.fx-market .mega-links li{
  margin-top: 10px;
}
.trading-wrapper{
  display: flex;
  padding-left:85px !important;
}
.trading-wrapper .box-2{
}
.mist{
  width: 65%;
  
}

.content .row header{
  color: black;
  font-size: 20px;
  font-weight: 500;
  margin-left: 55px;
}
.content .row-1 header{
    color: black;


    font-size: 20px;
    font-weight: 500;
  }
.content .row .mega-links{
    width: 100%;
    margin: auto;
    border-left: 1px solid rgba(255,255,255,0.09);
    
}
.row .mega-links {
  padding-left: 0px !important;
}

.row .mega-links li{
    /* padding: 8px 10px;
     */
     /* border: 1px solid green;   */
}
.product{
  padding:0px 0px;
}

.metatrader{
  padding:0px 28px;
}
.partner{
  padding:0px 28px;
}
.document{
  padding:0px 50px;
}
.row .mega-links li a{
    padding: 0 0px;
    display: block;
    font-size: 17px;
    color: black;
    border: 1px solid pink;
}
.navbar {
    background-color: rgb(245, 219, 219);
    position: fixed;
    width: 100%;
    z-index: 999;
    height: 65px;
    line-height: 65px;
  }
  
  .nav-links {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
  }
  
  .nav-links li {
    list-style: none;
  }
  
  .nav-links li span {
    color: var(--nav-link-color); 
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    transition: all 0.3s ease;
    font-family: "Lato-Regular";
    cursor: pointer;
  }
  
  .logo img {
    /* width: 80px;  */
  }
  
  .button {
    display: flex;
    flex-wrap: wrap; 
    justify-content: flex-end; 
  }
  
  .button a {
    text-decoration: none;
    padding: 8px 15px; 
  }
  .create{
    padding-left: 20px;
  }
  .mobile-ham{
    display: none;
  }
  .mobile-addr{
    display: none;
  }
  .my-market{
    margin-left: 25px;
  }
  .header-wrapper{
    position: sticky;
    top: 0px;
    z-index: 9999999;
  }
  
  @media only screen and (min-width: 320px)and (max-width:736px) {
  nav{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px !important;
    position: sticky;
    top: 0;
  }
  .wapper{
  min-width: 95% !important;
  height: 8vh !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;  
  }
  .logo{
    width:200px;
    height: 100%;
    margin: 0px !important;
  }
  .logo img{
    height: 100%;
    width:38%;
  }
  .spacing-div{
    display: none;
  }
  .mobile-ham{
    display: block;
    width: 15%;
    height:inherit;
    cursor: pointer;
  }
  .ham-wrapper{
   width: 100%;
   height: inherit;
   display: flex;
   align-items: center;
   justify-content: center;
  }
  .canvas-wrapper{
    margin-top: 20%;
  }
  .mobile-addr{
    display: block;
    /* min-height: 90vh; */
    margin: auto;
    width: 99%;
    padding-top: 20px;
    top: 80px;
    overflow: scroll;
    background-color: #FFFFFF;
    z-index: 999999;
  animation: slideInRight 0.5s ease;
  height: 100vh;
  position: fixed;
  }
  @keyframes slideInRight {
    from {
      transform: translateX(-100%); /* Start from outside the viewport */
    }
    to {
      transform: translateX(0); /* Move to the visible area */
    }
  }
  .link-div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:  15px;
  }
  .link-div p{
    margin-bottom: 0px;
    font-size: 16px;
    font-family: "Lato-Regular";
    color: var(--nav-link-color);
  }
  .link-content{
    width: 95%;
    margin: auto;
  }
  .title-link-div{
    display: flex;
    align-items: center;
    justify-content: start;
    /* height: 8vh; */
    margin-bottom: 10px;
    text-decoration: none;
  }
  .title-link-div a{
    text-decoration: none;
    color: var(--nav-link-color);

  }
  .title-link-div a{
    text-decoration: none;
    color: var(--nav-link-color);
  }
  .title-link-div p{
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 300;
    margin-right: 10px;
    font-family: "Lato-Light";
    color: var(--nav-link-color);
    font-size: 16px;
  }
  .fusin-hddr{
    font-family: "Lato-Bold";
    color: var(--nav-link-color);
    font-size: 15px;
  }
  .mob-btn-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: fit-content;
    margin-top: 50px;
  }
  .login-btn  a,
    .create-account  a{
      text-decoration: none;
    }
  
  .create-account{
    background: var(--button-bg);
    width: 90%;
    margin: auto;
    text-transform: uppercase;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .create-account a{
    text-decoration: none;
  }
  .login-btn a{
    text-decoration: none;
color:var(--button-bg);
  }
  .login-btn{
    width: 70%;
    border: none;
    padding: 10px;
    background: transparent;
    text-transform: uppercase;
  }
  .login-btn a{
    color: var(--border-color);

  }
  }
  @media (min-width: 1400px) {
    .link-info{
      margin-left: 200px !important;
    }
    .trading-wrapper{
      margin-left: 100px !important;
      width: 100%;

    }
  }
  @media(max-width:1440px){
    
   nav{
    /* height: 13vh; */
    padding: 0 80px;
    position: sticky !important;
    top: 0;
    
   }
   main {
    padding: 0 80px;
    
   }
   .header-open-account {
    width: 65%;
   }
   .nav-links li:hover  .drop-down,
.nav-links li:hover .mega-box{
  top:100%;
}
  }
  @media only screen and (min-width: 736px)and (max-width:1200px) {
    nav{
      width: 100% !important;
      padding:0px;
    }
    .logo{
width:75px;
    }
    .header-open-account {
      width:95%;
      margin-right: 10px;
    }
    .row{
      margin-left: 100px;
    }
  }