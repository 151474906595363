
.error{
    font-size: 12px;
    color:red;
    padding-right: 70%;

}
.error-email{
    font-size: 12px;
    color:red;
    padding-right: 70%; 
}
.error-phone{
    font-size: 12px;
    color:red;
    padding-right: 50%; 
}
.error-name{
    font-size: 12px;
    color:red;
    padding-right: 56%;
}
.error-phone-number{
    font-size: 12px;
    color:red;
    padding-right: 50%;
}
.error_message{
    font-size: 12px;
    color:red;
    padding-right: 60%;
}
.errormessage{
    font-size: 12px;
    color:red;
    padding-right: 60%;
}
.error-fullname{
    font-size: 12px;
    color:red;
    padding-right: 55%;  
}
.erroremail{
    font-size: 12px;
    color:red;
    padding-right: 66%; 
}
.page__content {
    margin: auto;
    background: #fff;
    box-shadow: 0 0 50px rgba(13,19,80,0.05);
    border-radius: 10px;
    padding: 64px 40px 0;
    box-sizing: border-box;
    max-width: 1280px;
    z-index: 1;
    position: relative;
}
.programm{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    margin-top: 80px;
   
}
.programm__list {
    background-color: #ebe98c1f;
    /* background: linear-gradient(180deg, rgba(197,174,255,0.1) -0.87%, rgba(191,214,255,0.1) 99.08%); */
    border-radius: 15px 0 0 15px;
    padding: 32px;
    display: grid;
    gap: 15px;
   
}
.title_align-left {
    text-align: left;
}
.title_align-lefts{
    text-align: center;
    padding-bottom: 40px !important;
}
.title_24px {
    font-size: 24px;
}
.title {
    font-family: "Lato-Regular";
    font-weight: 700;
    font-size: 24px;
    line-height: 58px;
    /* text-align: center; */
    color: var(--nav-link-color);
    margin: 0 auto;
    padding: 0;
}
.subtitle {
    text-align: start !important;
    font-family: Lato-Regular;
    font-size: 20px;
    line-height: 32px;
    padding: 0;
    color: var(--nav-link-color);
}
.subtitle p{
    margin-bottom: 0px !important;
}
.custom-form, .programm__title {

    background: #fff;
    box-shadow: 0 0 50px rgba(13,19,80,0.05);
    border-radius: 10px;
}
.programm__title {
    font-family: "Lato Regular";
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #0d1350;
    padding: 80px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.custom-form_no-border {
    background: #fff;
    box-shadow: none;
    width: 100%;
}
.custom-form {
    max-width: 920px;
    margin: 0 auto;
}
.field__legend {
    display: block;
    max-width: 100%;
    line-height: inherit;
    white-space: normal;
    width: auto;
    padding: 0px 7px;
    margin-left: 10px;
    font-family: Lato-Regular;
    font-size: 12px;
    line-height: 160%;
    color: var(--nav-link-color);
    font-weight: 400;
    position: absolute;
    top: -14px;
    background: #fff;
}
.platform__contact-us-input {
    max-width: 380px;
    border-radius: 10px;
    /* margin-bottom: 36px; */
}
.field {
    min-width: 0;
    /* padding: 0; */
    /* margin: 15px 0; */
    margin-top: 15px;
    position: relative;
    border: 1px solid #dadfec;
    border-radius: 3px;
    width: 100%;
}
.field__input {
    margin: 0;
    overflow: visible;
    font-family: "Lato-Regular";
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    height: 50px;
    outline: none;
    padding: 10px 15px;
    background-color: transparent;
    border: transparent;
    box-sizing: border-box;
}
.field__input_textarea{
    max-width: 380px;
    min-height: 220px;
}
.button-contact {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 10px 30px;
    background: var(--button-bg);
    backdrop-filter: blur(80px);
    border-radius: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    width: fit-content;
}
.button_primary {
    background: var(--button-bg);
    text-transform: uppercase;
    color: #fff;
    font-family: "Lato-Regular";
}
.button_primary:hover{
opacity: .7;
text-decoration: none;
}
.tab-panel{
    margin-top: 80px;
}
.field:focus-within{
border: 1px solid var(--border-color) !important;
}

@media (min-width:320px) and (max-width:765px){
    .platform__contact-us-input {
        margin-bottom: 20px !important;

    }
    .button-contact{
        font-size: 16px !important;
    }
    .programm{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .open-live button{
        width: 100%;
    }
    .accordion {
width: 100% !important;
    }
    .accordion-button p {
font-size: 13px !important;
margin-left: 30px !important;
/* margin-top: 0px !important; */
    }
    .accordion-button{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .accordion-body{
        font-size: 12px !important;
    }
    
}