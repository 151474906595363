.our-blogs{
   
    width:100%;
    height: 60%;
    /* background-color: #fff; */
    padding-top: 50px;
}
.About-top{
    padding-top: 50px;
    text-align: center;
}
.section-text h2{
    font-size: 50px !important;
}
.section-text h3{ 
    font-size: 30px !important;
}

@media (min-width:320px) and (max-width:765px) {
.section-text h2, .section-text h3{

    font-size: 22px !important;
}
}